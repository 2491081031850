<template>
<form @submit.prevent="form_type == 'add' ? addTourGuide() : updateTourGuide()">
    <div class="form-view"> <!-- Guide Form -->
        <Divider align="left" style="margin-top: 0">
            <div class="p-d-inline-flex p-ai-center" style="background-color: none">
                <span style="font-weight: 600">Tour guide information</span>
            </div>
        </Divider>

        <div class="p-grid p-fluid reserve-emp-form">
            <div class="p-col-12 p-md-9">
                <div class="p-grid">
                    <div class="p-col-12">
                        <label for="guideid">Guide ID: </label>
                        <InputText id="guideid" v-model="form.id" type="text" placeholder="ID is generated automatically." disabled />
                    </div>
                    <div class="p-col-6">
                        <label for="first_name">First name*:</label>
                        <InputText id="first_name" v-model="form.first_name" type="text" :class="{'p-invalid':v$.first_name.$error}" />
                        <small v-if="v$.first_name.$error" class="p-error">{{v$.first_name.required.$message.replace('Value', 'First name')}}</small>
                    </div>
                    <div class="p-col-6">
                        <label for="last_name">Last name:</label>
                        <InputText id="last_name" v-model="form.last_name" type="text" />
                    </div>
                    <div class="p-col-6">
                        <label for="sex">Gender*:</label>
                        <Dropdown v-model="selectedGuideGender" @change="onGenderChange()" :options="guide_gender" optionLabel="sex" :class="{'p-invalid':v$.sex.$error}" />
                        <small v-if="v$.sex.$error" class="p-error">{{v$.sex.required.$message.replace('Value', 'Sex')}}</small>
                    </div>
                    <div class="p-col-6">
                        <label for="guidingarea">Guiding area:</label>
                        <div style="display: flex">
                            <Dropdown style="min-width: 70%; width: 100%" v-model="selectedGuidingArea" :options="guiding_area" optionLabel="area">
                            </Dropdown>
                            <Button icon="pi pi-bars" @click="openPromptDialog" class="p-ml-3 pi-plus-resized p-button-secondary" style="min-width: 25px; height: 25px; margin: 2px" /> 

                            <Dialog header="Enter new guiding area:" v-model:visible="displayModal" :modal="true">
                                <template #header>
                                    <!-- <h5 style="margin: 0px">Guiding Area List</h5> -->
                                    <h5 v-if="!requestError.isError" style="margin: 0px">Guiding Area List</h5>
                                    <InlineMessage v-if="requestError.isError" severity="warn" class="p-ml-4">{{ requestError.warnMessage }}</InlineMessage>
                                </template>
                            
                                <DataTable
                                    :value="guidingarea_list"
                                    responsiveLayout="scroll"
                                    scrollHeight="245px"
                                    selectionMode="single"
                                    v-model:selection="selectedGuidingAreaRow"
                                    @rowSelect="onGuingAreaRowSelect"
                                    dataKey="id"
                                >
                                    <Column field="id" header="ID" :style="{'width':'80px', 'min-width':'30px'}"></Column>
                                    <Column field="area" header="Area" :style="{'min-width':'60px'}"></Column>

                                    <Column :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                                        <template #body="slotProps">
                                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="editGuidingArea(slotProps.data)"/>
                                            <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteGuidingArea(slotProps.data)" />
                                        </template>
                                    </Column>
                                    <ConfirmDialog></ConfirmDialog>
                                </DataTable>

                                <div class="guidingarealist-input-bar">
                                    <form @submit.prevent="guidingarea_form_type == 'add' ? addGuidingArea() : updateGuidingArea()">
                                        <InputText v-model="guidingarea_form.area" id="area" type="text" placeholder="Area" :class="{'p-invalid':v1$.area.$error}" />

                                            <Button v-if="guidingarea_form_type == 'add'" label="Add" type="submit" class="p-button-sm p-button-success" icon="pi pi-save" />
                                            <Button v-if="guidingarea_form_type == 'update'" label="Update" type="submit" class="p-button-sm p-button-success" icon="pi pi-save" />
                                    </form>
                                </div>
                            </Dialog>
                        </div>
                    </div>
                    <div class="p-col-6">
                        <label for="phone1">Phone 1*:</label>
                        <InputMask mask="999 999-999?9" v-model="form.phone1" :class="{'p-invalid':v$.phone1.$error}" />
                        <small v-if="v$.phone1.$error" class="p-error">{{v$.phone1.required.$message.replace('Value', 'Phone number')}}</small>
                    </div>
                    <div class="p-col-6">
                        <label for="phone1">Phone 2:</label>
                        <InputMask mask="999 999-999?9" v-model="form.phone2" />
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-md-3">
                <div class="preview-photo"> 
                    <UploadImage ref="uploadimageComponent" :previewImgUrl="setPreviewPhoto" />
                </div>
            </div>
        </div>
        <div class="p-grid p-fluid">
            <div class="p-col-12">
                <label for="email">Email:</label>
                <InputText id="email" type="text" v-model="form.email" :class="{'p-invalid':v$.email.$error}" />
                <small v-if="v$.email.$error" class="p-error">{{v$.email.$errors[0].$message}}</small>
            </div>
        </div>
        <div class="p-grid p-fluid">
            <div style="display: flex" class="p-mt-3 p-ml-2">
                <label for="language">Language*:</label>
                <div class="p-formgroup-inline p-ml-3">
                    <div v-for="language of guide_languages" :key="language.id" class="p-field-checkbox">
                        <Checkbox :id="language.id" name="language" v-model="selectedLanguage" :value="language" @change="onCheckBoxChanged(selectedLanguage)" :class="{'p-invalid': empty_language}"/>
                        <label :for="language.id">{{language.language}}</label>
                    </div>
                </div>
            </div>
        </div>

        <hr style="margin: 15px 0px; border: 0; border-top: 1px solid #cbcdcf"/>
        <div class="p-mt-2">
            <div v-if="form_type == 'add'">
                <Button label="Save" type="submit" class="p-button-success" icon="pi pi-save"></Button>
            </div>
            <div v-else>
                <Button label="Update" type="submit" class="p-button-warning" icon="pi pi-save"></Button>
            </div>
        </div>
    </div> <!-- End Guide Form -->
</form>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import Dialog from 'primevue/dialog';
import UploadImage from '../components/UploadImage';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

import GuidingAreaService from '../service/GuidingAreaService';
import TourGuideService from '../service/TourGuideService';

export default {
    setup(){
        const route = useRoute()
        const store = useStore()
        const toast = useToast()
        const confirm = useConfirm()
        const uploadimageComponent = ref(null) //$refs for UploadImage Component

        let form_type = ref('add')
        let form = reactive({})
        let displayModal = ref(false)
        let selectedGuideGender = ref()
        let selectedGuidingArea = ref()
        let new_guidingarea = ref('')
        let guiding_area = ref([])
        const guide_gender = [{sex: 'F'}, {sex: 'M'}]
        let setPreviewPhoto = ref()
        const tourguide_id = route.params.id;

        let guidingarea_form_type = ref('add')
        let guidingarea_list = ref([])
        let selectedGuidingAreaRow = ref()
        let guidingarea_form = reactive({})
        let old_guidingarea = ''
        let guidingarea_id = 0
        let requestError =  reactive({
            isError: false,
            warnMessage: ''
        })

        let guide_languages = ref([])
        const selectedLanguage = ref(guide_languages.value);
        let guide_lang = []
        let guide_lang_update = []
        let empty_language = ref(false)

        //On Created
        const guidingareaService = ref(new GuidingAreaService())
        const tourguideService = ref(new TourGuideService())
        
        //On Mounted
        onMounted(() => {
            initFormData();
            getLanguages()

            if (route.name == 'updatetourguide'){                
                form_type.value = 'update'; //Set form type to show Update button
                tourguideService.value.getTourGuideById(tourguide_id).then((data) => {
                    Object.assign(form, data)
                    
                    selectedGuideGender.value = {sex: data.sex}
                    setGuidingArea(data.guidingarea)
                    if(data.photo){
                        setPreviewPhoto.value = require('@/../public/images/'+data.photo);
                    }

                    if(data.TourGuide_Languages != []) {
                        for(const language of data.TourGuide_Languages) {
                            guide_lang = [...guide_lang, {
                                id: language.id,
                                language: language.language
                            }]
                        }
                        selectedLanguage.value = guide_lang
                    }
                })
            } else {
                setGuidingArea();
            }
        })

        const getLanguages = () => {
            tourguideService.value.getLanguages().then(data => {
                guide_languages.value = data
            })
        }

        const onCheckBoxChanged = (lang) => { //Check if user change language box and send new update data
            if(form_type.value == 'update') {
                guide_lang_update = lang
            }            
        }

        //Methods
        const initFormData = () => {
            const initForm = {
                first_name: '',
                last_name: '',
                sex: '',
                guidingarea: '',
                phone1: '',
                phone2: '',
                email: '',
                photo: ''
            }

            selectedGuideGender.value = ''
            selectedGuidingArea.value = ''
            selectedLanguage.value = ''

            Object.assign(form, initForm)
        }

        const setGuidingArea = async (setArea) => {
            await guidingareaService.value.getGuidingArea().then(data => guiding_area.value = data);
            if (setArea != ''){
                const area = guiding_area.value.filter(area => area.area == setArea)
                selectedGuidingArea.value = area[0]
            }
        }

        const isEmptyLanguage = () => {
            if(selectedLanguage.value.length == 0) {
                empty_language.value = true
                return true
            }
            empty_language.value = false
            return false
        }

        const addTourGuide = () => {
            if(validateForm() && !isEmptyLanguage()){
                if(selectedGuidingArea.value != null) {
                    form.guidingarea = selectedGuidingArea.value.area;
                }                
                if(getGuidePhotoName() == true){
                    uploadimageComponent.value.uploadImage();
                }
                
                tourguideService.value.addTourGuide(form, selectedLanguage.value).then((data) => {
                    if(data.errorResponse) {
                        toast.add({severity:'error', summary: 'Add Data Error ' + data.status, detail: data.errorResponse, life: 5000});        
                    } else {
                        initFormData(); //Clear form data
                        toast.add({severity:'success', summary: 'Info', detail:'Data successfully added.', life: 3000});
                        v$.value.$reset() //Reset validations
                    }
                })
            }
        }
        const updateTourGuide = () => {
            if(validateForm() && !isEmptyLanguage()){
                if(selectedGuidingArea.value != null) {
                    form.guidingarea = selectedGuidingArea.value.area;
                }

                if(getGuidePhotoName() == true){
                    uploadimageComponent.value.uploadImage();
                }

                tourguideService.value.updateTourGuide(tourguide_id, form, guide_lang_update).then((data) => {                    
                    if(data.errorResponse) {
                        toast.add({severity:'error', summary: 'Add Data Error ' + data.status, detail: data.errorResponse, life: 5000});        
                    } else {
                        toast.add({severity:'success', summary: 'Update Info', detail:'Data successfully updated.', life: 3000});
                    }  
                })
            }
        }
        const getGuidePhotoName = () => {
            if(store.state.uploadImage.hasPhoto == true) {
                const photoName = form.first_name.toLowerCase().replace(/\s/g, '') + form.last_name.toLowerCase().replace(/\s/g, '')
                store.dispatch('setPhotoName', photoName);

                form.photo = photoName + store.state.uploadImage.photoInfo.fileExt;
                
                return true;
            }
        }

        //======================== Guiding Area ========================
        const openPromptDialog = () => {
            displayModal.value = true;

            refreshGuidingAreaList()
        }

        const closePromptDialog = () => {
            displayModal.value = false;
        }

        const initGuidingAreaForm = () => {
            const initForm = {
                area: '',
            }

            Object.assign(guidingarea_form, initForm)
        }

        const refreshGuidingAreaList = () => {
            initGuidingAreaForm()
            guidingareaService.value.getGuidingArea().then(data => {
                guidingarea_list.value = data
            });
        }

        const onGuingAreaRowSelect = (event) =>  {
            guidingarea_form.area = event.data.area
            guidingarea_form_type.value = 'add'
        }

        const addGuidingArea = () => { 
            if(validateGuidingAreaForm()) {
                guidingareaService.value.addGuidingArea(guidingarea_form).then((data) =>{
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            showRequestError(true, data.message)
                        } else {
                            refreshGuidingAreaList()
                            v1$.value.$reset() //Reset validations
                            showRequestError(false, '')
                        }
                    } 
                })
            }
        }

        const editGuidingArea = (guidingarea_data) => {
            guidingarea_form_type.value = 'update'
            selectedGuidingAreaRow.value = guidingarea_data
            guidingarea_id = guidingarea_data.id

            old_guidingarea = guidingarea_data.area
            guidingarea_form.area = guidingarea_data.area
        }

        const updateGuidingArea = () => {
            if(validateGuidingAreaForm()) {
                if(old_guidingarea != guidingarea_form.area) {
                    guidingareaService.value.updateGuidingArea(guidingarea_id, guidingarea_form).then((data) =>{
                        if(!data.errorResponse) {
                            if(data.status == 400) {
                                showRequestError(true, data.message)
                            } else {
                                refreshGuidingAreaList()
                                guidingarea_form_type.value = 'add'
                                v1$.value.$reset() //Reset validations
                                showRequestError(false, '')
                            }
                        } 
                    })
                }
            }
        }

        const confirmDeleteGuidingArea = (data) => {
            confirm.require({
                message: 'Do you want to delete this record?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const area_id = data.id
                    guidingareaService.value.deleteGuidingArea(area_id).then((data) => {
                        if(!data.errorResponse){
                            refreshGuidingAreaList()
                        }
                    })
                }
            });
        }

        const showRequestError = (isError, warnMessage) => {
            requestError.isError = isError
            requestError.warnMessage = warnMessage

            setTimeout(() => {
                requestError.isError = false
                requestError.warnMessage = ''
            }, 4000);
        }
        //======================== End Guiding Area ========================

        //======================== Form Validation ========================
        const rules = computed(() =>  {
            return {
                first_name: { required },
                sex: { required },
                phone1: { required },
                email: { email },
            }            
        })
        const v$ = useVuelidate(rules, form)
        const validateForm = () => {
            v$.value.$validate();
            if(!v$.value.$error){
                return true
            } else {
                return false
            }
        }

        //Validate in Guiding area add/update form
        const guidingarea_rules = computed(() =>  {
            return {
                area: { required }
            }            
        })
        const v1$ = useVuelidate(guidingarea_rules, guidingarea_form)
        const validateGuidingAreaForm = () => {
            v1$.value.$validate();
            if(!v1$.value.$error){
                return true
            } else {
                return false
            }
        }

        const onGenderChange = () => {
            form.sex = selectedGuideGender.value.sex;
        }

        return {
            form_type,
            form,
            v$,
            v1$,
            displayModal,
            selectedGuideGender,
            selectedGuidingArea,
            new_guidingarea,
            guiding_area,
            guide_gender,
            setPreviewPhoto,
            uploadimageComponent,
            // confirm,
            // toast,
            // validateField,

            //Return methods
            // guidingareaService,
            // tourguideService,
            openPromptDialog,
            closePromptDialog,
            // setGuidingArea,
            addTourGuide,
            updateTourGuide,
            onGenderChange,
            // getGuidePhotoName,
            // validateForm

            //Guiding Area
            guidingarea_list,
            selectedGuidingAreaRow,
            guidingarea_form,
            guidingarea_form_type,
            onGuingAreaRowSelect,
            editGuidingArea,
            addGuidingArea,
            updateGuidingArea,
            confirmDeleteGuidingArea,
            requestError,

            guide_languages,
            selectedLanguage,
            onCheckBoxChanged,
            empty_language,
        }
    },
        components: {        
        Dialog,
        UploadImage,
    }
}

</script>

<style lang="scss" scoped>
    /* Render delete button in Guiding Area dropdown */
.dropdown-item-button{ 
    float: right; 
    color: red;
    margin-top: 3px;
}

.guidingarealist-input-bar {
    margin-top: 1.2rem;
}

.guidingarealist-input-bar .p-inputtext{
    width: 18rem;
    margin-right: .8rem;
}

.small-datatable-button {
    width: 28px;
}
.small-datatable-button.p-button-icon-only.p-button-rounded {
    height: 28px;
}
.p-col-6 {
    padding: .3rem .5rem;
}
.p-field-checkbox {
    label {
        width: 60px;
    }
}
</style>
